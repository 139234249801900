import React from 'react';
import Button from 'react-bootstrap/Button';

import styles from './CtaButton.module.css';

const CtaButton: React.FC<{
  type?: string;
  disabled?: boolean;
  onClick?: () => void;
  variant?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'primary-invert'
    | 'light'
    | 'dark-bg';
  className?: string;
}> = ({
  children,
  type,
  disabled = false,
  onClick,
  variant = 'primary',
  className,
}) => (
  <Button
    type={type}
    className={`
      ${className !== undefined ? className : ''}
      ${
        variant === 'primary'
          ? styles.ctaButton
          : variant === 'secondary'
          ? styles.ctaButtonSecondary
          : variant === 'primary-invert'
          ? styles.ctaButtonInvert
          : variant === 'danger'
          ? styles.ctaButtonDanger
          : variant === 'light'
          ? styles.ctaButtonLight
          : variant === 'dark-bg'
          ? styles.ctaButtonDarkBG
          : ''
      }
    `}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </Button>
);

export default CtaButton;
