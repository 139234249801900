import React, { useEffect, useRef, useMemo, useState } from 'react';

import styles from './Video.module.css';

const Inner: React.FC<{
  maxWidth: number;
  maxHeight: number;
  onResize?: (width: number, height: number) => void;
}> = ({ maxWidth, maxHeight, onResize, children }) => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const elementRef = useRef(element);
  useEffect(() => {
    elementRef.current = element;
  }, [element]);

  const onResizeRef = useRef(onResize);
  onResizeRef.current = onResize;

  const observerRef = useRef(
    onResizeRef.current !== undefined &&
      typeof window !== 'undefined' &&
      window.ResizeObserver
      ? new window.ResizeObserver(() => {
          if (
            elementRef.current !== null &&
            onResizeRef.current !== undefined
          ) {
            const width = elementRef.current.clientWidth;
            const height = elementRef.current.clientHeight;
            onResizeRef.current(width, height);
          }
        })
      : null
  );

  const observer = observerRef.current;
  useEffect(() => {
    if (observer && element) {
      observer.observe(element);
      return () => {
        observer.unobserve(element);
      };
    }
  }, [observer, element]);

  return (
    <div
      ref={(ref) => setElement(ref)}
      className={styles.inner}
      style={{
        maxWidth,
        maxHeight,
      }}
    >
      {children}
    </div>
  );
};

const Video: React.FC<{
  mp4: string;
  gif: string;
  width: number;
  height: number;
  topPadding?: number;
}> = ({ mp4, gif, width, height, topPadding = 0 }) => {
  const [calculatedTopPadding, setCalculatedTopPadding] = useState(topPadding);

  const onResize = useMemo(() => {
    if (topPadding === 0) {
      return undefined;
    }
    return (newWidth: number, newHeight: number) => {
      const ratio = topPadding / height;
      const newTopPadding = Math.ceil(ratio * newHeight);
      setCalculatedTopPadding(newTopPadding);
    };
  }, [height, topPadding]);

  return (
    <div
      className={styles.container}
      style={{
        paddingTop: calculatedTopPadding,
      }}
    >
      <Inner maxWidth={width} maxHeight={height} onResize={onResize}>
        <video autoPlay loop muted playsInline className={styles.video}>
          <source src={mp4} type="video/mp4" />
          <img src={gif} className={styles.image} />
        </video>
      </Inner>
    </div>
  );
};

export default Video;
