import { createQueryString } from '../universal/util';
import { environment, config } from './config';

export const sendEvent = ({
  type = 'track',
  name,
  data,
}: {
  type?: 'track' | 'trackCustom';
  name: string;
  data?: Record<string, unknown>;
}) => {
  const win: any = window;
  if (win.fbq) {
    win.fbq(type, name, data || {});
  }
};

const sendCustomEvent = ({
  name,
  data,
}: {
  name: string;
  data?: Record<string, unknown>;
}) => {
  sendEvent({ type: 'trackCustom', name, data });
};

type Category =
  | 'consumer'
  | 'business'
  | 'business-register'
  | 'dashboard'
  | 'project'
  | 'course'
  | 'forbidden'
  | 'settings'
  | 'billing'
  | 'payment-method'
  | 'subscribe'
  | 'cancel'
  | 'certificate'
  | 'admin'
  | 'auth-error';

export const sendPageHitEvent = ({ category }: { category: Category }) => {
  sendCustomEvent({
    name: 'PageHit',
    data: {
      hostname: window.location.hostname,
      environment,
      content_category: category,
    },
  });
};

export const sendWaitlistEvent = ({ category }: { category: Category }) => {
  sendCustomEvent({ name: 'Waitlist', data: { content_category: category } });
};

export const sendFeedbackEvent = ({ category }: { category: Category }) => {
  sendCustomEvent({ name: 'Feedback', data: { content_category: category } });
};

const pixelId = config.facebook.pixel.id;

export const pixelJS = `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', ${JSON.stringify(pixelId)});
fbq('track', 'PageView');`;

export const pixelImageUrl = `https://www.facebook.com/tr?${createQueryString({
  id: pixelId,
  ev: 'PageView',
  noscript: 1,
})}`;
