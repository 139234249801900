import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './HomeHeader.module.css';
import { useAuth } from '../lib/frontend/auth';
import { HomeHeaderPage } from '../lib/frontend/types';
import { CATEGORIES } from '../lib/universal/types';
import Logo from './Logo';
import InstrumentedCtaButton from './InstrumentedCtaButton';
import InstrumentedLink from './InstrumentedLink';

const HomeHeader: React.FC<{
  onClickCta: () => void;
  page: HomeHeaderPage;
  buttonLabel?: string;
}> = ({ onClickCta, page, buttonLabel = 'Get Started' }) => {
  const { isLoggedIn } = useAuth();

  const ctaButtonEventLabel =
    page === 'business'
      ? 'clickBusinessLandingPageCtaButton'
      : page === 'business-register'
      ? 'clickBusinessRegisterPageCtaButton'
      : page === 'terms'
      ? 'clickTermsPageCtaButton'
      : page === 'privacy'
      ? 'clickPrivacyPageCtaButton'
      : page === 'about'
      ? 'clickAboutPageCtaButton'      
      : 'clickConsumerLandingPageCtaButton';

  return (
    <Row>
      <Col>
        <Container>
          <Row className={`mx-0 ${styles.topBar}`}>
            <Col
              className={`text-center text-md-left ${styles.topBarLogo}`}
              md="auto"
            >
              <Logo />
            </Col>
            <Col className={`d-none d-md-block ${styles.topCta}`}>
              <div className={styles.forConsumerLink}>
                <InstrumentedLink
                  internalHref="/"
                  label={`${page}_landing_top_bar_for_consumer_link`}
                  category={CATEGORIES.LANDING}
                >
                  Personal
                </InstrumentedLink>
              </div>
              <div className={styles.forBusinessLink}>
                <InstrumentedLink
                  internalHref="/business"
                  label={`${page}_landing_top_bar_for_business_link`}
                  category={CATEGORIES.LANDING}
                >
                  Business
                </InstrumentedLink>
              </div>
              <div className={styles.signInLink}>
                {isLoggedIn ? (
                  <InstrumentedLink
                    internalHref="/dashboard"
                    label={`${page}_landing_top_bar_dashboard_link`}
                    category={CATEGORIES.LANDING}
                  >
                    Dashboard
                  </InstrumentedLink>
                ) : (
                  <InstrumentedLink
                    href="/api/auth/login"
                    label={`${page}_landing_top_bar_sign_in_link`}
                    category={CATEGORIES.LANDING}
                  >
                    Sign In
                  </InstrumentedLink>
                )}
              </div>
              <InstrumentedCtaButton
                text={buttonLabel}
                variant="light"
                eventLabel={ctaButtonEventLabel}
                onClick={onClickCta}
              />
            </Col>
            <Col xs={12} className={`d-md-none ${styles.mobileCta}`}>
              <div className={styles.forConsumerLink}>
                <InstrumentedLink
                  internalHref="/"
                  label={`${page}_landing_mobile_cta_for_consumer_link`}
                  category={CATEGORIES.LANDING}
                >
                  Personal
                </InstrumentedLink>
              </div>
              <div className={styles.forBusinessLink}>
                <InstrumentedLink
                  internalHref="/business"
                  label={`${page}_landing_mobile_cta_for_business_link`}
                  category={CATEGORIES.LANDING}
                >
                  Business
                </InstrumentedLink>
              </div>
              <div className={styles.signInLink}>
                {isLoggedIn ? (
                  <InstrumentedLink
                    internalHref="/dashboard"
                    label={`${page}_landing_mobile_cta_dashboard_link`}
                    category={CATEGORIES.LANDING}
                  >
                    Dashboard
                  </InstrumentedLink>
                ) : (
                  <InstrumentedLink
                    href="/api/auth/login"
                    label={`${page}_landing_mobile_cta_sign_in_link`}
                    category={CATEGORIES.LANDING}
                  >
                    Sign In
                  </InstrumentedLink>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default HomeHeader;
