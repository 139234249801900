import React, { ComponentProps, useCallback } from 'react';
import CtaButton from './CtaButton';
import { sendEvent } from '../lib/frontend/stats';
import { CATEGORIES, EventPayload } from '../lib/universal/types';

type ButtonEventPayload = Extract<
  EventPayload,
  { metadata: { buttonText: string } }
>;

type CtaButtonProps = ComponentProps<typeof CtaButton>;

const InstrumentedCtaButton: React.FC<
  CtaButtonProps & {
    eventLabel: ButtonEventPayload['name'];
    text: string;
  }
> = (allProps) => {
  const { eventLabel, text, onClick, ...restOfProps } = allProps;
  const onClickButton = useCallback(() => {
    sendEvent(
      CATEGORIES.LANDING,
      {
        name: eventLabel,
        metadata: {
          buttonText: text,
        },
      },
      { event_label: text }
    );
    if (onClick !== undefined) {
      onClick();
    }
  }, [onClick, text, eventLabel]);

  return (
    <CtaButton onClick={onClickButton} {...restOfProps}>
      {text}
    </CtaButton>
  );
};

export default InstrumentedCtaButton;
