import React, { useEffect, useState, useRef } from 'react';

type TitleKeywordType =
  | 'marketing'
  | 'product'
  | 'SEO'
  | 'finance'
  | 'sales'
  | 'support';

const INITIAL_TITLE_KEYWORD = 'marketing';

const getNextKeyword = (
  keyword: TitleKeywordType,
  isBusiness: boolean
): TitleKeywordType => {
  if (isBusiness) {
    switch (keyword) {
      case 'marketing':
        return 'product';
      case 'product':
        return 'support';
      case 'support':
        return 'finance';
      case 'finance':
        return 'sales';
      case 'sales':
        return 'marketing';
    }
  } else {
    switch (keyword) {
      case 'marketing':
        return 'product';
      case 'product':
        return 'SEO';
      case 'SEO':
        return 'marketing';
    }
  }
  throw new Error(`Invalid keyword ${keyword}`);
};

const animateKeywordTyping = (
  keyword: string,
  setter: (val: string) => void,
  onComplete: () => void
): ReturnType<typeof setInterval> => {
  let index = 0;
  const interval = setInterval(() => {
    if (index >= keyword.length) {
      clearInterval(interval);
      onComplete();
      return;
    }

    setter(keyword.substr(0, index + 1));
    index += 1;
  }, 100);
  return interval;
};

const AnimatedTitleKeyword: React.FC<{ isBusiness?: boolean }> = ({
  isBusiness = false,
}) => {
  const [titleKeyword, setTitleKeyword] = useState<string>(
    INITIAL_TITLE_KEYWORD
  );
  const nextKeyword = useRef(getNextKeyword(INITIAL_TITLE_KEYWORD, isBusiness));

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    let interval: ReturnType<typeof setInterval> | null = null;

    const nextWord = () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        const newKeyword = nextKeyword.current;
        nextKeyword.current = getNextKeyword(newKeyword, isBusiness);
        setTitleKeyword('');
        if (interval !== null) {
          clearInterval(interval);
        }
        interval = animateKeywordTyping(newKeyword, setTitleKeyword, nextWord);
      }, 1500);
    };

    nextWord();

    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
      if (interval !== null) {
        clearInterval(interval);
      }
    };
  }, [isBusiness]);

  return <>{titleKeyword}</>;
};

export default AnimatedTitleKeyword;
